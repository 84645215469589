import { useApolloClient } from '@apollo/client';
import { getCachedQueryVariables } from '@aurora/shared-apollo/cacheQueryVariables';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import ConditionalWrapper from '@aurora/shared-client/components/common/ConditionalWrapper/ConditionalWrapper';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import { PagerPosition } from '@aurora/shared-client/components/common/Pager/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import NodeAvatar from '@aurora/shared-client/components/nodes/NodeAvatar/NodeAvatar';
import NodeDescription from '@aurora/shared-client/components/nodes/NodeDescription/NodeDescription';
import NodeIcon from '@aurora/shared-client/components/nodes/NodeIcon/NodeIcon';
import NodeTitle from '@aurora/shared-client/components/nodes/NodeTitle/NodeTitle';
import nodeViewsQuery from '@aurora/shared-client/components/nodes/NodeViews.query.graphql';
import useImperativeQueryWithTracing from '@aurora/shared-client/components/useImperativeQueryWithTracing';
import Icons from '@aurora/shared-client/icons';
import type {
  Board,
  CoreNode,
  CoreNodeEdge,
  PageInfo,
  ParentNode
} from '@aurora/shared-generated/types/graphql-schema-types';
import { SearchSnippetKey } from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useCallback, useContext, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { ItemType, NodeViewVariant } from '../../../../types/enums';
import { ApolloQueryCacheKey, ViewSize } from '../../../../types/enums';
import type {
  NodeMembersCountFragment,
  NodeMembershipTypeFragment,
  NodeViewFragment,
  NodeViewsQuery,
  NodeViewsQueryVariables
} from '../../../../types/graphql-types';
import type { ItemViewVariantFC } from '../../../entities/types';
import useTranslation from '../../../useTranslation';
import NodeLatestActivityTime from '../../NodeLatestActivityTime/NodeLatestActivityTime';
import NodeLink from '../../NodeLink/NodeLink';
import NodeMembersCount from '../../NodeMembersCount/NodeMembersCount';
import NodeTopicsCount from '../../NodeTopicsCount/NodeTopicsCount';
import localStyles from './NodeViewInline.module.css';

function isContextNodeBoard(node: NodeViewFragment | CoreNode): node is Board {
  return node.nodeType === NodeType.BOARD;
}

/**
 * Node View - Inline
 *
 * @constructor
 *
 * @author Adam Ayres, Willi Hyde
 */
const NodeViewInline: ItemViewVariantFC<
  NodeViewFragment | CoreNode,
  ItemType.NODE | ItemType.NODE_SEARCH,
  NodeViewVariant.INLINE
> = ({
  entity: node,
  className,
  useNodeAvatar,
  nodeIconSize,
  useNodeTitle,
  useNodeDescription,
  useNodeTopicsCount,
  useNodeMembersCount,
  useChildNodes,
  viewSize,
  useNodeLink,
  useBoardCount,
  useCategoryName,
  clampTitleLines,
  useEntireItemAsLink,
  nestedNodesPageSize,
  isNestedNode,
  useUnreadMessagesCount,
  useNodeLatestActivityTime,
  textKey,
  useNodeTopicsCountIcon,
  useTextDescription,
  descriptionClampLines,
  useLockIcon,
  nodeIconType,
  nestedNodePager,
  useNeutralLabel,
  useNodeType = false,
  titleClassName,
  linkClassName,
  footerClassName,
  childrenSorts,
  searchSnippet,
  titleAs
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage } = useTranslation(EndUserComponent.NODE_VIEW_INLINE);
  const { nodeType } = node;
  const childCount = (node as ParentNode)?.children?.edges?.length;
  const { membershipType } = node as NodeMembershipTypeFragment;
  const [pageInfo, setPageInfo] = useState<PageInfo>((node as ParentNode)?.children?.pageInfo);
  const [nodeChildrenLoading, setNodeChildrenLoading] = useState<boolean>(false);
  const { contextNode } = useContext(AppContext);
  const { cache } = useApolloClient();
  const searchSnippetForNodeTitle =
    searchSnippet &&
    searchSnippet.find(snippet => snippet.key === SearchSnippetKey.Title)?.content[0];
  const searchSnippetForNodeDescription =
    searchSnippet &&
    searchSnippet.find(snippet => snippet.key === SearchSnippetKey.NodeDescription)?.content[0];

  function NodeLinkWrapper({
    className: linkWrapperClassname,
    children,
    ariaLabel = ''
  }): React.ReactElement {
    return (
      <NodeLink className={cx(linkWrapperClassname)} node={node} ariaLabel={ariaLabel}>
        {children}
      </NodeLink>
    );
  }

  const nodeChildren = useImperativeQueryWithTracing<NodeViewsQuery, NodeViewsQueryVariables>(
    module,
    nodeViewsQuery
  );

  /**
   * This function handles loading of the next set of nested nodes under a parent node in an optimised way so as to improve performance. This is done by fetching the nestd nodes for their respective parent and updating the Apollo Cache manually.
   */
  const onShowMore = useCallback(async () => {
    setNodeChildrenLoading(true);
    const childrenConstraints = {
      parentId: { eq: node.id }
    };
    const nodeChildrenResult = await nodeChildren({
      constraints: childrenConstraints,
      sorts: childrenSorts,
      first: nestedNodesPageSize,
      after: pageInfo?.endCursor,
      useFullPageInfo: true,
      useNodeAvatar: useNodeAvatar,
      useNodeDescription: useNodeDescription,
      useNodeParent: true,
      useNodeTopicsCount: useNodeTopicsCount,
      useNodeLatestActivityTime: useNodeLatestActivityTime,
      useNodeUnreadCount: useUnreadMessagesCount,
      useMembershipType: !!(useLockIcon || useNodeMembersCount)
    });

    setPageInfo(nodeChildrenResult.data?.coreNodes.pageInfo as PageInfo);

    const descendantEdges = nodeChildrenResult.data.coreNodes.edges;
    const cachedCommunityStructureVariables: NodeViewsQueryVariables = getCachedQueryVariables(
      cache,
      `${ApolloQueryCacheKey.NODE_STRUCTURE}:${contextNode.id}`
    );
    const cachedCommunityStructureQuery = cache.readQuery<NodeViewsQuery, NodeViewsQueryVariables>({
      query: nodeViewsQuery,
      variables: cachedCommunityStructureVariables
    });

    const updatedNodeEdges = [...cachedCommunityStructureQuery.coreNodes.edges].map(
      (item, index) => {
        if (item.node.id === node.id) {
          const cachedCommunityStructureNode = cachedCommunityStructureQuery.coreNodes.edges[index]
            .node as ParentNode;
          const updatedNodeEdge = {
            ...cachedCommunityStructureQuery.coreNodes.edges[index],
            node: {
              ...cachedCommunityStructureNode,
              children: {
                ...cachedCommunityStructureNode?.children,
                edges: [...cachedCommunityStructureNode?.children?.edges, ...descendantEdges],
                pageInfo: {
                  ...nodeChildrenResult.data?.coreNodes.pageInfo
                }
              }
            }
          };
          return updatedNodeEdge;
        }
        return item;
      }
    );

    const updatedData = {
      coreNodes: {
        ...cachedCommunityStructureQuery.coreNodes,
        edges: [...updatedNodeEdges]
      }
    };

    cache.writeQuery<NodeViewsQuery, NodeViewsQueryVariables>({
      query: nodeViewsQuery,
      variables: cachedCommunityStructureVariables,
      data: updatedData as NodeViewsQuery
    });

    setNodeChildrenLoading(false);
  }, [
    node.id,
    nodeChildren,
    nestedNodesPageSize,
    pageInfo?.endCursor,
    useNodeAvatar,
    useNodeDescription,
    useNodeTopicsCount,
    useNodeLatestActivityTime,
    useUnreadMessagesCount,
    useLockIcon,
    useNodeMembersCount,
    cache,
    contextNode.id,
    childrenSorts
  ]);

  function renderNodeAvatar(): React.ReactElement {
    return (
      useNodeAvatar && (
        <section className={cx('lia-node-icon')}>
          <ConditionalWrapper
            condition={useNodeLink && !useEntireItemAsLink}
            wrapper={props => (
              <NodeLinkWrapper
                className={cx('d-block')}
                ariaLabel={formatMessage('iconlink', { name: node.title, type: node.nodeType })}
              >
                {props.children}
              </NodeLinkWrapper>
            )}
          >
            <NodeAvatar
              node={node}
              size={nodeIconSize}
              className={cx('rounded-sm')}
              fallback={(): JSX.Element => <NodeIcon node={node} size={nodeIconSize} useFrame />}
            />
          </ConditionalWrapper>
        </section>
      )
    );
  }

  function renderNodeIcon(): React.ReactElement {
    return (
      nodeIconType === 'icon' && (
        <NodeIcon className={cx('lia-node-icon')} node={node} size={nodeIconSize} useFrame />
      )
    );
  }

  function renderNodeTitle(): React.ReactElement {
    return (
      useNodeTitle && (
        <ConditionalWrapper
          condition={useNodeLink && !useEntireItemAsLink}
          wrapper={props => (
            <NodeLinkWrapper
              className={cx(linkClassName, 'lia-link', { 'lia-node-title': !isNestedNode })}
            >
              {props.children}
            </NodeLinkWrapper>
          )}
        >
          <NodeTitle
            node={node}
            as={titleAs}
            className={cx(
              'lia-title h6',
              { 'mb-0': !isNestedNode },
              { 'lia-node-title lia-node-title-sm': isNestedNode },
              {
                [`lia-g-clamp lia-g-clamp-${clampTitleLines}`]: clampTitleLines
              },
              titleClassName
            )}
            membershipType={membershipType}
            showLockIcon={useLockIcon}
            titleSearchSnippet={searchSnippetForNodeTitle}
          />
        </ConditionalWrapper>
      )
    );
  }

  function renderTopicsCount(): React.ReactElement {
    return (
      <NodeTopicsCount
        node={node as NodeViewFragment}
        className={cx('lia-node-footer-item lia-g-divider d-inline-block')}
        useIcon={useNodeTopicsCountIcon}
        useNeutralLabel={useNeutralLabel}
        iconColor={IconColor.GRAY_600}
        iconSize={IconSize.PX_16}
      />
    );
  }

  function renderDescription(): React.ReactElement {
    return (
      useNodeDescription &&
      node.description && (
        <NodeDescription
          node={node}
          as="small"
          useTextDescription={useTextDescription}
          clampLines={descriptionClampLines}
          descriptionSearchSnippet={searchSnippetForNodeDescription}
          className={cx('lia-node-content', { 'lia-node-content-sm': isNestedNode === true })}
        />
      )
    );
  }

  function renderNodeMembersCount(): React.ReactElement {
    return (
      <NodeMembersCount
        className={cx('lia-node-footer-item lia-g-divider d-inline-block')}
        iconColor={IconColor.GRAY_600}
        node={node as NodeMembersCountFragment}
        useIcon={false}
      />
    );
  }

  function renderCategoryName(categoryName: String): React.ReactElement {
    return <span className={cx('lia-g-divider lia-node-footer-item')}>{categoryName}</span>;
  }

  function renderBoardCount(): React.ReactElement {
    return (
      <span className={cx('lia-g-divider lia-node-footer-item')}>
        {formatMessage('boardCount', { boardCount: childCount })}
      </span>
    );
  }

  function renderNodeLatestActivityTime(): React.ReactElement {
    return (
      <NodeLatestActivityTime
        node={node}
        as="small"
        className={cx('lia-g-divider lia-node-footer-item d-inline-block')}
        textKey={textKey}
      />
    );
  }

  function renderUnreadMessagesCount(): React.ReactElement {
    return (
      node.unreadMessagesCount > 0 && (
        <span className={cx('lia-g-divider lia-node-footer-item d-inline-block lia-unread-count')}>
          {formatMessage('unreadCount', { count: node.unreadMessagesCount })}
        </span>
      )
    );
  }

  function renderNodeType() {
    const type: string = (node as NodeViewFragment).__typename;

    return (
      <span className={cx('lia-g-divider lia-node-footer-item')}>
        {formatMessage(`nodeType.${type}`)}
      </span>
    );
  }

  function renderFooter(): React.ReactElement {
    let categoryName: string;
    if (isContextNodeBoard(node) && node.parent.displayId !== 'top') {
      ({
        parent: { title: categoryName }
      } = node);
    }
    const showMembersCount = useNodeMembersCount && membershipType;
    const showCategoryName = useCategoryName && categoryName;
    const showChildrenCount =
      useBoardCount && nodeType !== NodeType.BOARD && childCount && childCount !== 0;
    const showFooter =
      useNodeLatestActivityTime ||
      showMembersCount ||
      showCategoryName ||
      showChildrenCount ||
      useUnreadMessagesCount ||
      useNodeTopicsCount;
    return (
      showFooter && (
        <section className={cx(footerClassName, `lia-node-footer lia-node-footer-${viewSize}`)}>
          {useNodeType && renderNodeType()}
          {useNodeLatestActivityTime && renderNodeLatestActivityTime()}
          {showMembersCount && renderNodeMembersCount()}
          {useNodeTopicsCount && renderTopicsCount()}
          {useUnreadMessagesCount && renderUnreadMessagesCount()}
          {showCategoryName && renderCategoryName(categoryName)}
          {showChildrenCount && renderBoardCount()}
        </section>
      )
    );
  }

  const shouldRenderNestedNodes =
    useChildNodes &&
    childCount !== undefined &&
    childCount > 0 &&
    node.nodeType !== NodeType.GROUPHUB;

  function renderNestedNodes(): React.ReactElement {
    return (
      shouldRenderNestedNodes && (
        <ul
          className={cx('lia-node-children list-unstyled', {
            'lia-node-children-indent-left': nodeIconType === 'none'
          })}
          data-testid="NodeChildren.List"
        >
          {((node as ParentNode).children.edges as Array<CoreNodeEdge>).map(item => (
            <li
              key={item.node.id}
              className={cx('lia-node-children-item')}
              data-testid="NodeChildrenList.Item"
            >
              <NodeViewInline
                entity={item.node}
                useNodeAvatar={useNodeAvatar}
                nodeIconSize={IconSize.PX_30}
                useNodeTitle
                useNodeDescription
                useNodeTopicsCount={useNodeTopicsCount}
                useNodeMembersCount={useNodeMembersCount}
                useNodeTopicsCountIcon={useNodeTopicsCountIcon}
                useChildNodes
                useNodeLink={useNodeLink}
                useEntireItemAsLink={useEntireItemAsLink}
                isNestedNode
                useNodeLatestActivityTime={useNodeLatestActivityTime}
                viewSize={ViewSize.SM}
                useLockIcon={useLockIcon}
                nodeIconType={nodeIconType}
                textKey={textKey}
                useUnreadMessagesCount={useUnreadMessagesCount}
                useNeutralLabel={useNeutralLabel}
                nestedNodePager={nestedNodePager}
              />
            </li>
          ))}
        </ul>
      )
    );
  }

  const NodeChildrenPager = (): React.ReactElement => {
    const buttonTitle = formatMessage('pagerTitle');
    return (
      <footer
        className={cx('lia-node-pager', {
          'lia-node-pager-center': nestedNodePager.pagerPosition === PagerPosition.CENTER
        })}
      >
        <Button
          title={buttonTitle}
          loading={nodeChildrenLoading}
          variant={ButtonVariant.LINK}
          onClick={onShowMore}
          className={cx('lia-g-loader-btn', {
            'lia-node-children-indent-left': nodeIconType === 'none'
          })}
          data-testid="NodeChildren.Pager"
        >
          <Icon
            icon={Icons.ChevronDownIcon}
            color={IconColor.PRIMARY}
            size={IconSize.PX_16}
            className={cx('lia-g-mr-5')}
          />
          {buttonTitle}
        </Button>
      </footer>
    );
  };

  const shouldRenderChildrenPager =
    node.nodeType !== NodeType.GROUPHUB &&
    nestedNodePager.showNestedNodesPager &&
    pageInfo?.hasNextPage;

  return (
    <ConditionalWrapper
      condition={useEntireItemAsLink}
      wrapper={props => (
        <NodeLinkWrapper className={cx(className, 'lia-link')}>{props.children}</NodeLinkWrapper>
      )}
    >
      <article
        className={cx(
          !useEntireItemAsLink && className,
          'lia-node',
          {
            [`lia-node-${viewSize}`]: viewSize === ViewSize.SM || viewSize === ViewSize.XS
          },
          {
            'lia-node-children-pager-center': nestedNodePager.pagerPosition === PagerPosition.CENTER
          }
        )}
        data-testid="NodeViewInline"
      >
        {renderNodeAvatar()}
        {renderNodeTitle()}
        {renderNodeIcon()}
        {renderDescription()}
        {renderFooter()}
        {/* Adding child nodes props in inline view because the community page is
        using it for now. Not sure these belongs here. */}
        {renderNestedNodes()}
        {shouldRenderChildrenPager && <NodeChildrenPager />}
      </article>
    </ConditionalWrapper>
  );
};

export default NodeViewInline;
